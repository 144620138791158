import "@fontsource/nunito/300.css";
import "@fontsource/nunito/400.css";
import "@fontsource/nunito/400-italic.css";
import "@fontsource/nunito/700.css";

import cn from "classnames";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";

import Heading from "../../atoms/heading/Heading";
import Icons from "../../core/ui/images/icomoon/symbol-defs.svg";
import HelloPaw from "../../images/templates/about-us/hello-paw.svg";
import SectionTitle from "../../molecules/section-title/SectionTitle";
import LandingBottomCta from "../../organisms/landing-bottom-cta/LandingBottomCta";
import Meta from "../../organisms/meta/meta";
import PageWrapper from "../../organisms/page-wrapper/PageWrapper";
import withLandingWrapper from "../../organisms/with_landing_wrapper";
import { Locale } from "../../settings/countries";
import useAxeptioScriptLoader from "../../utils/hooks/useAxeptioScriptLoader";
import { getCountryByLocale } from "../../utils/locale-configuration-utils";
import * as styles from "./AboutUsLanding.module.scss";

interface Image {
  default: string;
}

const logoSVG: Image = require("../../images/templates/about-us/logo-white-barkibu.svg") as Image;

const logoSVGSrc: string = logoSVG.default;

const AboutUsLanding = ({ data }) => {
  const { i18n } = useI18next();
  const { t } = useTranslation();

  const {
    slug,
    locale,
    metaTitle,
    metaDescription,
    heroTitle,
    heroSubtitle,
    numbers,
    whoWeAreTitle,
    whoWeAreSubtitle,
    employeeCarouselDescription,
    employeeCarousel,
    companyValuesTitle,
    companyValuesItems,
    ourHistoryTitle,
    ourHistoryDescription,
    landingBottomTitle,
    landingBottomCta,
    footerDescription,
  } = data.contentfulAboutUsLandingLocal;

  const alternateVersions = data.alternateVersions.nodes.map(
    ({ localizedContent }) => localizedContent
  );

  const countryCodeIso = getCountryByLocale(locale);

  void i18n.changeLanguage(locale);
  useAxeptioScriptLoader(locale);

  return (
    <>
      <Meta
        title={metaTitle}
        description={metaDescription}
        slug={slug}
        alternateVersions={alternateVersions}
        lang={locale as Locale}
        bodyClass={styles.aboutUsLanding}
        disableTranslations={false}
      />
      <PageWrapper>
        <main className={cn(styles.main, "or-main")}>
          <section className={styles.heroAboutUs}>
            <Link to={`/${locale}/`} className={styles.heroLogo}>
              <img src={logoSVGSrc} alt="Barkibu" width="90px" height="21px" />
            </Link>
            <SectionTitle
              title={heroTitle}
              subtitle={parse(heroSubtitle)}
              headingLevel={1}
              adoptionClassName={styles.sectionTitle}
            />
          </section>
          <section className={styles.numbers}>
            <ul>
              {numbers.map((item, index) => {
                return <li key={index}>{parse(item)}</li>;
              })}
            </ul>
          </section>
          <section className={styles.whoAreWe}>
            <SectionTitle
              title={whoWeAreTitle}
              subtitle={whoWeAreSubtitle}
              adoptionClassName={styles.sectionTitle}
            />
          </section>
          <div className={styles.employeeCarousel}>
            <ul>
              <li>
                <HelloPaw />
                {parse(employeeCarouselDescription)}
              </li>
              {employeeCarousel.images.map(({ id, description, image }) => {
                return (
                  <li key={id}>
                    <figure>
                      <img src={image.file.url} alt="" />
                      <figcaption>{parse(description)}</figcaption>
                    </figure>
                  </li>
                );
              })}
            </ul>
          </div>
          <section className={styles.ourValues}>
            <SectionTitle title={companyValuesTitle} adoptionClassName={styles.sectionTitle} />
            <ul>
              {companyValuesItems.map(({ id, icon, description, title }) => {
                return (
                  <li key={id}>
                    <svg aria-hidden="true">
                      <use href={`#icon-${icon}`} />
                    </svg>
                    <Heading level={3}>{title}</Heading>
                    <p>{description}</p>
                  </li>
                );
              })}
            </ul>
          </section>
          <section className={styles.ourHistory}>
            <section className={styles.ourHistoryDescription}>
              <SectionTitle title={ourHistoryTitle} adoptionClassName={styles.sectionTitle} />
              {parse(ourHistoryDescription.internal.content)}
            </section>
            <section className={styles.ourHistoryImage}>
              <StaticImage
                placeholder="blurred"
                src={"../../images/templates/about-us/barkibu-dogs-and-humans.jpg"}
                alt={t("landing.designed_by_petparents.image.1")}
              />
            </section>
          </section>
          <section className={styles.cta}>
            <SectionTitle title={landingBottomTitle} adoptionClassName={styles.sectionTitle} />
            <LandingBottomCta
              data={landingBottomCta}
              countryCodeIso={countryCodeIso}
              adoptionClassName={styles.aboutUsBottomCta}
            />
          </section>
        </main>

        <footer>
          <p>{parse(footerDescription.internal.content)}</p>
        </footer>
        <Icons />
      </PageWrapper>
    </>
  );
};

export default withLandingWrapper(AboutUsLanding);

export const query = graphql`
  query AboutUsLandingQuery($id: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulAboutUsLandingLocal(id: { eq: $id }) {
      slug
      locale: node_locale
      metaTitle
      metaDescription
      heroTitle
      heroSubtitle
      numbers
      whoWeAreTitle
      whoWeAreSubtitle
      employeeCarouselDescription
      employeeCarousel {
        images {
          description
          image {
            file {
              url
            }
          }
          id
        }
      }
      companyValuesTitle
      companyValuesItems {
        id
        description
        title
        icon
      }
      ourHistoryTitle
      ourHistoryDescription {
        internal {
          content
        }
      }
      landingBottomTitle
      landingBottomCta {
        cta
      }
      footerDescription {
        internal {
          content
        }
      }
    }
    alternateVersions: allContentfulAboutUsLandingGlobal {
      nodes {
        localizedContent {
          id
          slug
          locale: node_locale
        }
      }
    }
  }
`;
